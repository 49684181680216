<template>
    <v-container>
        <v-card>
            <v-card-title>
                <v-icon left color="primary">mdi-credit-card</v-icon>
                Suscripción Recurrente</v-card-title>
            <v-divider />
            <v-card-text class="pt-4">
                <!-- Available Subscriptions -->
                <p>A continuación podrás seleccionar el plan que deseas suscribirte. Recuerda que el pago se hará
                    mensualmente.</p>
                <v-radio-group v-model="selectedPlan" class="my-2" v-if="!loading" :disabled="brickInitialized">
                    <v-radio v-for="plan in availablePlans" :key="plan.id"
                        :label="`${plan.reason} - ${formatCurrency(plan.auto_recurring.transaction_amount)} / mensual`"
                        :value="plan"></v-radio>
                </v-radio-group>
                <v-skeleton-loader v-if="loading" type="heading" class="mb-2" />
                <v-skeleton-loader v-if="loading" type="heading" class="mb-2" />
                <v-skeleton-loader v-if="loading" type="heading" class="mb-4" />

                <!-- Initial form: User must provide email -->
                <v-form ref="initialForm" v-model="formValid" lazy-validation v-show="false">
                    <v-text-field v-model="email" label="Correo Electrónico" :rules="[rules.required, rules.email]"
                        required></v-text-field>
                </v-form>

                <!-- Button to initialize the payment Brick -->
                <v-btn block large v-if="!brickInitialized" :disabled="!formValid || !selectedPlan" color="primary"
                    @click="initializeBrick">
                    <v-icon left>mdi-check-circle</v-icon>
                    Iniciar Confirmación
                </v-btn>

                <!-- Payment Brick container -->
                <div id="cardPaymentBrick_container" class="my-4"></div>

                <!-- Feedback messages -->
                <v-alert v-if="message" :type="messageType" dismissible class="mt-2">
                    {{ message }}
                </v-alert>
            </v-card-text>

            <!--  <v-card-actions v-if="brickInitialized">
                <v-btn color="primary" @click="submitPayment">
                    Confirmar Pago
                </v-btn>
            </v-card-actions> -->
        </v-card>
    </v-container>
</template>

<script>
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getFirestore, collection, onSnapshot } from 'firebase/firestore';

export default {
    name: "SubscriptionComponent",
    data() {
        return {
            loading: true,
            email: this.$store.state.Auth.token.claims.email,
            selectedPlan: null,
            availablePlans: [],
            formValid: false,
            mp: null,
            bricksBuilder: null,
            cardBrickController: null,
            brickInitialized: false,
            message: "",
            messageType: "",
            unsubscribe: null,
            rules: {
                required: (v) => !!v || "Este campo es requerido",
                email: (v) => /.+@.+\..+/.test(v) || "El correo electrónico debe ser válido",
            },
        };
    },
    mounted() {
        this.loadMercadoPagoSDK();
        this.subscribeToPlans();
    },
    beforeDestroy() {
        if (this.unsubscribe) {
            this.unsubscribe();
        }
    },
    methods: {
        formatCurrency(amount) {
            return new Intl.NumberFormat('es-UY', {
                style: 'currency',
                currency: 'UYU'
            }).format(amount);
        },
        subscribeToPlans() {
            const db = getFirestore();
            const plansRef = collection(db, 'mercadoPagoSubscriptions');
            this.unsubscribe = onSnapshot(plansRef, (snapshot) => {
                this.availablePlans = snapshot.docs
                    .map(doc => ({
                        id: doc.id,
                        ...doc.data()
                    }))
                    .filter(plan => plan.status === 'active')
                    .sort((a, b) => a.auto_recurring.transaction_amount - b.auto_recurring.transaction_amount);

                this.loading = false;
            }, (error) => {
                console.error('Error fetching plans:', error);
                this.message = 'Error loading subscription plans';
                this.messageType = 'error';
            });
        },
        loadMercadoPagoSDK() {
            if (!window.MercadoPago) {
                const script = document.createElement("script");
                script.src = "https://sdk.mercadopago.com/js/v2";
                script.async = true;
                script.onload = this.initMercadoPago;
                document.head.appendChild(script);
            } else {
                this.initMercadoPago();
            }
        },
        initMercadoPago() {
            this.mp = new MercadoPago("APP_USR-c7cf23dd-7ca7-414a-976c-c1f3cbe20ccd");
            this.bricksBuilder = this.mp.bricks();
        },
        initializeBrick() {
            this.$refs.initialForm.validate();
            if (!this.formValid || !this.selectedPlan) {
                this.message = "Por favor seleccione un plan y proporcione un correo electrónico válido.";
                this.messageType = "warning";
                return;
            }

            this.brickInitialized = true;

            this.$nextTick(() => {
                const settings = {
                    initialization: {
                        amount: this.selectedPlan.auto_recurring.transaction_amount,
                        payer: {
                            email: this.email,
                        },
                    },
                    callbacks: {
                        onReady: () => {
                            console.log("Payment Brick is ready.");
                        },
                        onSubmit: async (cardFormData) => {
                            try {
                                this.message = "Procesando suscripción...";
                                this.messageType = "info";

                                const functions = getFunctions();
                                const subscribe = httpsCallable(functions, 'gym/subscribe');
                                const result = await subscribe({
                                    email: this.email,
                                    cardToken: cardFormData.token,
                                    amount: this.selectedPlan.auto_recurring.transaction_amount,
                                    planId: this.selectedPlan.id,
                                    userId: this.$store.state.Auth.token.claims.user_id,
                                    daysPerWeek: this.selectedPlan.daysPerWeek
                                });

                                console.log("result", result);

                                if (result.data.success) {
                                    this.message = "¡Suscripción creada exitosamente!";
                                    this.messageType = "success";
                                    this.$notify({
                                        title: 'Suscripción Recurrente Creada',
                                        text: 'Tu pago ha sido aprobado.',
                                        type: 'success'
                                    });
                                    this.$router.push('/perfil')
                                } else {
                                    this.message = "La creación de la suscripción falló.";
                                    this.messageType = "error";
                                }
                            } catch (error) {
                                console.error("Subscription error:", error);
                                this.message = "Ocurrió un error al procesar su suscripción.";
                                this.messageType = "error";
                            }
                        },
                        onError: (error) => {
                            console.error("Brick error:", error);
                            this.message = "Hubo un error con el widget de pago.";
                            this.messageType = "error";
                        },
                    },
                };

                this.bricksBuilder
                    .create("cardPayment", "cardPaymentBrick_container", settings)
                    .then((controller) => {
                        this.cardBrickController = controller;
                        this.message = "Widget de pago inicializado. Por favor ingrese los datos de su tarjeta.";
                        this.messageType = "info";
                    })
                    .catch((error) => {
                        console.error("Error creating Brick:", error);
                        this.message = "Error al inicializar el widget de pago. Por favor intente nuevamente más tarde.";
                        this.messageType = "error";
                        this.brickInitialized = false;
                    });
            });
        },
        submitPayment() {
            if (this.cardBrickController) {
                this.cardBrickController.submit();
            } else {
                this.message = "El widget de pago aún no está inicializado. Por favor espere e intente nuevamente.";
                this.messageType = "error";
            }
        },
    },
};
</script>

<style scoped>
.v-radio-group {
    margin-bottom: 20px;
}
</style>
